import type { AuctionCar } from '@autobid/ui/types/Car'
import type { AuctionStateStatic } from '@autobid/ui/types/AuctionState'

export const COLORS = {
  red: {
    bg: 'bg-red text-white',
    text: 'text-red'
  },
  green: {
    bg: 'bg-success text-white',
    text: 'text-success'
  },
  darkOrange: {
    bg: 'bg-[#ED8633] text-white',
    text: 'text-[#ED8633]'
  },
  orange: {
    bg: 'bg-[#F59C00] text-white',
    text: 'text-[#F59C00]'
  },
  blue: {
    bg: 'bg-[#004968] text-white',
    text: 'text-[#004968]'
  }
}

type ColorType = 'bg' | 'text'

export const getBasedOnPrice = (
  car: AuctionCar,
  state: AuctionStateStatic,
  bidAmount: number,
  colorType: ColorType
) => {
  if (state.isNotSold && !car.price.current) return COLORS.red[colorType]

  if (
    state.isPriceDissolution ||
    (state.isSold && car.stage === 'FINISHED') ||
    (state.isSold && state.isUnderAuctionReservation)
  ) {
    return COLORS.green[colorType]
  }

  if (state.isUnderAuctionReservation) return COLORS.darkOrange[colorType]

  if (bidAmount >= car.price.start) return COLORS.green[colorType]

  if (state.isUnderReservation && car.stage === 'FINISHED') {
    return COLORS.orange[colorType]
  }

  return COLORS.red[colorType]
}

export const getColorBasedOnState = (
  car: AuctionCar,
  state: AuctionStateStatic,
  bidAmount: number,
  colorType: ColorType
) => {
  if (car.stage !== 'FINISHED' && car.state === 'RESERVED') {
    return getBasedOnPrice(car, state, bidAmount, colorType)
  }

  if (state.isSold) return COLORS.green[colorType]

  if (state.isNotSold) return COLORS.red[colorType]

  if (state.isUnderAuctionReservation) return COLORS.darkOrange[colorType]

  if (state.isUnderReservation) return COLORS.orange[colorType]
}

export const getColor = (
  car: AuctionCar,
  state: AuctionStateStatic,
  bidAmount: number,
  colorType: ColorType
) => {
  return getColorBasedOnState(car, state, bidAmount, colorType)
}

export const getTextColor = (
  state: AuctionStateStatic,
  stage: AuctionCar['stage'],
  target: 'price' | 'state',
  currentPrice?: number
) => {
  if (!currentPrice && target !== 'state') return undefined

  if (state.isNotSold) return target === 'state' ? COLORS.red.text : undefined

  if (state.isSold) return COLORS.green.text

  if (state.isUnderAuctionReservation) return COLORS.darkOrange.text

  if (state.isUnderReservation) {
    return stage === 'FINISHED' || target === 'state'
      ? COLORS.orange.text
      : undefined
  }
}
